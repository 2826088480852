@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply mb-12 text-5xl font-semibold sm:text-4xl md:text-6xl;
  }

  h3 {
    @apply mb-8 mt-4 text-3xl font-extrabold sm:text-4xl md:text-5xl;
  }

  body {
    @apply bg-white font-primary text-gray-800;
  }
}

:root {
  --primary-color: theme('colors.primary.500');
}

video {
  outline: none;
  border: none;
  /* Applying a mask image to hide possible borders */
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
}

dd {
  margin-top: 1rem !important;
}

.section-container {
  @apply mx-auto flex max-w-5xl flex-col items-center px-4 text-center;
}

.section-content {
  @apply mx-auto max-w-2xl text-lg opacity-75;
}

.gradient-text {
  @apply bg-gradient-to-tl from-primary-500 to-primary-100 bg-clip-text font-bold text-transparent;
}

.feature-container {
  @apply flex flex-col space-y-2 rounded-xl bg-white px-6 py-10 text-left shadow-md;
}

.feature-icon {
  @apply mb-2 flex h-20 w-20 items-center justify-center rounded-3xl bg-primary-10 py-1;
}

.feature-title {
  @apply flex flex-row items-center space-x-2 text-xl font-semibold;
}

.feature-description {
  @apply font-medium opacity-60;
}

.feature-premium {
  @apply rounded-full bg-primary-500 px-4 py-2 text-sm font-medium text-primary-10;
}

html:not([data-scroll='0']) header {
  border-bottom-width: 0.5px;
  border-color: #f1f3ff;
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: translateY(25px);
  }

  45% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.animate-appear {
  animation: appear 0.75s ease-out forwards;
}

@keyframes press {
  25%,
  60% {
    transform: translateX(-0.2rem) translateY(-0.2rem);
  }
  85% {
    transform: translateX(-0.5rem) translateY(-0.5rem);
  }
}

.animate-press {
  animation: press 2s ease-in-out infinite;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(5px);
  }
}

.animate-float {
  animation: float 5s ease-in-out infinite;
}

@keyframes scaleIn {
  0% {
    opacity: 0;
    transform: scale(0.55, 0.55);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

.animate-scaleIn {
  animation: scaleIn 0.75s ease-in-out forwards;
}

.animate-scaleInSlow {
  animation: scaleIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  20% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.animate-fadeIn {
  animation: fadeIn 1.25s ease-in-out forwards;
}

@keyframes opacityIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animate-opacityIn {
  animation: opacityIn 0.35s ease-in-out forwards;
}

.shapes-background {
  background: url('img/shapes.svg') repeat;
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .oscillate-background {
    background-image: url('img/oscillate.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 25%;
    background-origin: padding-box;
    width: 100%;
  }
}

@media screen and (max-width: 1023px) {
  .feature-connector::before {
    content: '';
    position: absolute;
    width: 85%;
    height: 50%;
    background-color: #f1f3ff;
    top: -25%;
    left: 7.5%;
    z-index: -10;
    opacity: 0.5;
  }

  .feature-connector:first-child::before {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .feature-connector::after {
    content: '';
    position: absolute;
    width: 50%;
    height: 85%;
    background-color: #f1f3ff;
    top: 7.5%;
    right: -35%;
    z-index: -10;
    opacity: 0.5;
  }

  .feature-connector:first-child::before {
    display: none;
  }
  .feature-connector:nth-child(2)::before {
    display: none;
  }
  .feature-connector:nth-child(2)::after {
    display: none;
  }
  .feature-connector:nth-child(4)::after {
    display: none;
  }
  .feature-connector:nth-child(6)::after {
    display: none;
  }
  .feature-connector:nth-child(8)::after {
    display: none;
  }
  .feature-connector:nth-child(10)::after {
    display: none;
  }
  .feature-connector:nth-child(12)::after {
    display: none;
  }
  .feature-connector:nth-child(13)::after {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .feature-connector::after,
  .feature-connector::before {
    content: '';
    position: absolute;
    background-color: #f1f3ff;
    z-index: -10;
    opacity: 0.5;
  }

  .feature-connector::after {
    width: 85%;
    height: 50%;
    top: -25%;
    left: 7.5%;
  }

  .feature-connector::before {
    width: 75%;
    height: 85%;
    top: 7.5%;
    left: 75%;
  }

  .feature-connector:first-child::after,
  .feature-connector:nth-child(2)::after,
  .feature-connector:nth-child(3)::before,
  .feature-connector:nth-child(3)::after,
  .feature-connector:nth-child(6)::before,
  .feature-connector:nth-child(9)::before,
  .feature-connector:nth-child(12)::before,
  .feature-connector:nth-child(13)::before {
    display: none;
  }
}

/* HAMBURGER */
.hamburger {
  cursor: pointer;
  width: 20px;
  height: 18px;
  transition: all 0.25s;
  position: relative;
}

.hamburger-top,
.hamburger-bottom {
  position: absolute;
  width: 20px;
  height: 2px;
  top: 0;
  left: 0;
  transform: rotate(0);
  transition: all 0.5s;
}

.hamburger-top {
  transform: translateY(2px);
}

.hamburger-bottom {
  transform: translateY(10px);
}

.open {
  transform: rotate(90deg);
}

.open .hamburger-top {
  transform: rotate(45deg) translateY(6px) translateX(6px);
}

.open .hamburger-bottom {
  transform: rotate(-45deg) translateY(6px) translateX(-6px);
}

@media (min-width: theme('screens.md')) {
  .hamburger:not(.open) {
    display: none;
  }
}

/* FONTS */

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@keyframes glowing {
  0%,
  100% {
    box-shadow: 0px 2px 15px 5px rgba(65, 132, 234, 0.8);
  }
  50% {
    box-shadow: 0px 2px 15px 0px rgba(65, 132, 234, 0.6);
  }
}

.box-glow {
  box-shadow: 0px 2px 15px 5px rgba(65, 132, 234, 0.8);
  animation: glowing 5s infinite ease-in-out;
}

/** FAQ Accordion **/

.toggle-icon {
  transition: transform 0.2s ease-in-out;
}

.hidden-line {
  display: none;
}

.faq-item:not(:last-child)::after {
  content: '';
  display: block;
  height: 1px;
  opacity: 0.3;
  background-color: #d1d5db; /* Tailwind gray-300 */
  margin-top: 1.5rem;
}

.link {
  @apply text-primary-500 opacity-100 hover:opacity-80;
}
